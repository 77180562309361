<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <sub-shipping-table
            type="dark"
            :title="$t('sidebar.vehicles')"
          ></sub-shipping-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubShippingTable from "../Tables/Shippings/SubShippingTable.vue";
export default {
  name: "Sub-Categories",
  components: {
    SubShippingTable,
  },
};
</script>